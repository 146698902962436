@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: 'Aktiv Grotesk Extended';
  src: url('/public/fonts/AktivGroteskEx-XBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Aktiv Grotesk Extended';
  src: url('/public/fonts/AktivGroteskEx-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Aktiv Grotesk Extended';
  src: url('/public/fonts/AktivGroteskEx-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Aktiv Grotesk';
  src: url('/public/fonts/BasisGrotesqueArabicPro-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Basis Grotesque';
  src: url('/public/fonts/BasisGrotesquePro-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Basis Grotesque';
  src: url('/public/fonts/Net-BasisGrotesquePro-Bold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

body {
  font-family: 'Basis Grotesque', sans-serif;
}

.slick-slider.slick-initialized,
.slick-list,
.slick-track,
div.slick-slide > div {
  height: 95% !important;
}

 .custom-dots {
  display: flex !important;
  justify-content: center;
  bottom: 10% !important;
  z-index: 0;
} 
.custom-dots li {
  width: 12px;
  height: 12px;
}
.custom-dots li button:before {
  content: '';
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background-color: #fff;
  display: block;
  margin: 0 auto;
}

 .custom-dots li {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 10px;
  border: 1.5px solid #BEBEBE;
  cursor: pointer;
  transition: all 0.3s ease;
}

.custom-dots li.slick-active {
  background-color: #BEBEBE;
} 
.custom-dots li button:before {
  content: none;
}

.measure-app-modal>div:first-child>:nth-child(4) {
  z-index: 20 !important;
  top: 236px;
}

.measure-app-modal>div:first-child>:nth-child(5)>:last-child>:first-child>:last-child>:first-child>p:first-child {
  margin-bottom: -50px;
}

.measure-app-modal>div:first-child>:last-child {
  z-index: 20;
  bottom: 194px;
}